import React from "react";
import Layout from "../../Layout";
import "./style.scss";
import data from "./data";
import SEO from "../../seo";

export default function Laboratories() {
  return (
    <Layout>
      <SEO
        title="Chinmaya Vidyalaya | Well-Equipped Laboratories"
        description="The Vidyalaya's state of the art campus includes up to date computer and research labs, as well as an Atal Tinkering Lab, for science and robotics."
        img="https://chinmayavvdelhi.ac.in/images/Blackele/Chinmaya_link_preview.jpg"
        keywords=""
      />
      <div className="infra">
        <h1 className="heading">Laboratories</h1>

        <p className="infra__text">
          The Chinmaya Vidyalaya approach to learning strongly encourages
          students to reach out of the confines of the four walls of the
          classroom, and regularly takes learning into activity and research
          centres.
          <br />
          <br />
          The school’s Atal Tinkering Lab, for science and robotics, as well as
          the computer and research lab, are important avenues for curiosity
          development and exploration.
          <br />
          <br />
          After all, nothing is quite as poetic as science in motion.
        </p>

        <div className="infra__images">
          <div className="row">
            {data.map((item) => (
              <div className="col col-12 col-md-6 col-xl-4">
                <img className="infra__images__img" src={item}></img>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}
